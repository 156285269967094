'use client';

import * as React from 'react';
import { createPortal } from 'react-dom';

import SimpleNotification, {
  NotificationVariant,
} from '@/rollbar-ui/Notifications/Simple';

type NotificationShape = {
  content: React.ReactNode | React.ReactNode[];
  variant: NotificationVariant;
};

type PropTypes = {
  children: React.ReactNode | React.ReactNode[];
};

const NotificationContext = React.createContext((c: NotificationShape) => {});

export default function NotificationContextProvider({ children }: PropTypes) {
  const portalNode = React.useRef<HTMLElement>();

  React.useEffect(() => {
    portalNode.current =
      document.getElementById('toast-portal-root') || undefined;
  }, []);

  const [notification, setNotification] = React.useState<NotificationShape>();

  return (
    <NotificationContext.Provider value={setNotification}>
      {children}
      {notification &&
        portalNode.current &&
        createPortal(
          <SimpleNotification
            variant={notification.variant}
            onClose={() => setNotification(undefined)}
          >
            {notification.content}
          </SimpleNotification>,
          portalNode.current
        )}
    </NotificationContext.Provider>
  );
}

export function useSendNotification(): (c: NotificationShape) => void {
  return React.useContext(NotificationContext);
}
