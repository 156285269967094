import { BoltIcon } from '@heroicons/react/20/solid';
import { useRollbar } from '@rollbar/react';
import classNames from 'classnames';
import Link from 'next/link';
import qs from 'qs';
import { useMemo } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { UpsellFeature } from '@/generated/graphql';
import { useComparePlansUrl } from '@/lib/urls';

import { getUpgradePlanFeatureList } from './util';

type UpgradePlanCardFeatureListProps = {
  upsellFeature: UpsellFeature;
  onClose: () => unknown;
};

export function UpgradePlanCardFeatureList({
  upsellFeature,
  onClose,
}: UpgradePlanCardFeatureListProps) {
  const { planId: activePlanId } = useAccountInfo();
  const rollbar = useRollbar();

  const comparePlansPageLink = useComparePlansUrl();
  const {
    user: { isOwner },
  } = useAccountInfo();

  const learnMoreQueryParams = {
    feature: isOwner ? upsellFeature : `${upsellFeature}-non-owner`,
    selectedPlan: 'essentials',
    referalPage: encodeURIComponent(window.location.href),
  };

  const learnMoreLink = `${comparePlansPageLink}?${qs.stringify(
    learnMoreQueryParams
  )}`;

  const featureList = useMemo(() => {
    try {
      return getUpgradePlanFeatureList({ activePlanId, upsellFeature });
    } catch (err) {
      rollbar.error('Unable to get upgrade feature list', { activePlanId });
      return [];
    }
  }, [activePlanId, rollbar, upsellFeature]);

  return (
    <ol className="flex flex-col list-image-none list-none gap-y-4 font-light">
      {featureList?.map(({ name, highlighted }) => (
        <li
          className="flex flex-row gap-x-2 items-center list-image-none list-none text-sm"
          key={name}
        >
          <div className="w-5 rounded-full bg-white p-0.5">
            <BoltIcon className="fill-[#5045FF]" />
          </div>

          <span
            className={classNames(
              'text-left !text-white !justify-start !p-0 hover:text-red-500 text-sm',
              { 'font-semibold': highlighted }
            )}
          >
            {name}
          </span>
        </li>
      ))}
      <li className="flex flex-row gap-x-2 items-center list-image-none list-none text-sm">
        <div className="w-5 rounded-full bg-white p-0.5">
          <BoltIcon className="fill-[#5045FF]" />
        </div>

        <Link
          href={learnMoreLink}
          onClick={onClose}
          target="_blank"
          rel="noopener noreferrer"
          className="!justify-start !p-0 !text-white hover:text-red-500 font-normal text-sm underline"
        >
          And much more ↗
        </Link>
      </li>
    </ol>
  );
}
