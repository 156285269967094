export const ONBOARDING = {
  EXPERIMENT: {
    SKIP_WELCOME: 'experiement_skippedWelcome_0323',
  },
  ACTION: {
    SKIP_LINK_CLICKED_STEP_ONE: 'onboarding.skipped_step_one',
    SKIP_LINK_CLICKED_STEP_TWO: 'onboarding.skipped_step_two',
    SELECT_DIFFERENT_SDK_CLICKED: 'onboarding.view_other_sdks',
    SEARCH_CLICKED: 'onboarding.search_interaction',
    SUPPORT_LINK_CLICKED: 'onboarding.clicked_support',
    DOCS_LINK_CLICKED: 'onboarding.clicked_docs',
    FRAMEWORK_SELECTED: 'onboarding.framework_selected',

    // new onboarding actions

    SKIPPED_STEP: 'v_7_0.onboarding.skipped_step',
    CONTINUE_CLICKED: 'v_7_0.onboarding.continue_clicked',
    BACK_CLICKED: 'v_7_0.onboarding.back_clicked',
    VIEWED_STEP: 'v_7_0.onboarding.viewed_step',
    INTEGRATION_CLICKED: 'v_7_0.onboarding.integration_clicked',
    SELECTED_SDK: 'v_7_0.onboarding.selected_sdk',
    DELETE_INTEGRATION_CLICKED: 'v_7_0.onboarding.delete_integration_clicked',
    VIEW_FIRST_ITEM_CLICKED: 'v_7_0.onboarding.view_first_item_clicked',
    INVITE_TEAM_MEMBER_CLICKED: 'v_7_0.onboarding.invite_team_member_clicked',
    COPY_CLICKED: 'v_7_0.onboarding.copy_clicked',
    VIDEO_STATE_CHANGED: 'v_7_0.onboarding.video_state_changed',
    SUBMIT_INVITE_TEAM_MEMBERS: 'v_7_0.onboarding.submit_invite_team_members',
    SUBMIT_INTEGRATION_CONFIGURATION_CHANGE:
      'v_7_0.onboarding.submit_integration_configuration_change',
    INTEGRATION_FORM_CLOSED: 'v_7_0.onboarding.integration_form_closed',
    SDK_CHANGE_CLICKED: 'v_7_0.onboarding.sdk_change_clicked',
    SDK_REVIEW_CLICKED: 'v_7_0.onboarding.sdk_review_clicked',
    SDK_DELETE_CLICKED: 'v_7_0.onboarding.sdk_delete_clicked',
    INTEGRATION_CONFIGURE_CLICKED:
      'v_7_0.onboarding.integration_configure_clicked',
    INTEGRATION_DELETE_CLICKED: 'v_7_0.onboarding.integration_delete_clicked',
    ADD_MORE_INTEGRATIONS_CLICK: 'v_7_0.onboarding.add_more_integrations_click',
  },
};

export const PROJECT_SETTINGS = {
  SEARCH: {
    NAME: 'project_settings.search.text',
    PROJECT_STATUS: 'project_settings.search.project_status',
    INTEGRATION: 'project_settings.search.integration',
    INTEGRATION_STATUS: 'project_settings.search.integration_status',
  },
  NAVIGATION: {
    MEMBERS: 'project_settings.collaborators.clicked',
    ACCESS_TOKEN: 'project_settings.access_token.clicked',
    INTEGRATION_CONFIGURATION: 'project_settings.integration.clicked',
    INTEGRATION_SETUP: 'project_settings.integration_setup.clicked',
    CLICKED_SETTINGS: 'project_settings.settings_page.clicked',
  },
  ACTION: {
    OPEN_ACTION_MENU: 'project_settings.actions.clicked',
    ENABLE: 'project_settings.actions.enable',
    DISABLE: 'project_settings.actions.disable',
    DISABLE_CONFIRMED: 'project_settings.actions.disable.confirmed',
    DELETE: 'project_settings.actions.delete',
    DELETE_CONFIRMED: 'project_settings.actions.delete.confirmed',
  },
  DUPLICATE_PROJECT_MODAL: {
    FROM_CREATE_PROJECT_BUTTON: 'project.duplication.create',
    FROM_PROJECT_SETTINGS_PAGE: 'project.duplication.configuration',
    CLICKED: 'project.duplication.clicked',
    DUPLICATED: 'project.duplication.complete',
    CANCELED: 'project.duplication.canceled',
    ERROR_OCCURRED: 'project.duplication.error_occurred',
  },
  CONFIGURE_PROJECT_MODAL: {
    CREATE_PROJECT: {
      CREATED: 'project_created',
      CANCELED: 'project_settings.create_project.canceled',
    },
    SELECT_SDK: {
      SELECTED: 'project_settings.sdk.sdk_selected',
      CANCEL: 'project_settings.sdk.cancel',
      SEARCH: 'project_settings.sdk.search',
    },
    INSTALL_SDK: {
      SELECT_DIFFERENT: 'project_settings.create_project.view_other_sdks',
    },
    FIRST_ITEM_SENT: 'project_settings.create_project.first_item_sent',
    ACCESS_TOKEN: {
      CREATED: 'proejct_settings.access_token.created',
    },
  },
};
export const FILTERS = {
  WARNING: {
    DATERANGE_PREVIOUS_OUTSIDE_RETENTION_PERIOD:
      'filter.daterange_previous_outside_retention_period',
    DATERANGE_PREVIOUS_WARNING_DISMISSED:
      'filter.daterange_previous_warning_dismissed',
  },
  ERROR: {
    DATERANGE_OUTSIDE_RETENTION_PERIOD:
      'filter.daterange_outside_retention_period',
    DATERANGE_RETENTION_ERROR_DISMISSED:
      'filter.daterange_retention_error_dismissed',
  },
};
export const TEAM = {
  CREATE: 'team.create',
  DELETE: 'team.delete',
  LEAVE: 'team.leave',
  ADD_PROJECTS_TO_TEAM: 'team.add_project_to_team',
  REMOVE_PROJECTS_FROM_TEAM: 'team.remove_projects_from_team',
  USER: {
    REMOVE_USER: 'team.user.remove_user',
    ADD_USER_TO_TEAM: 'team.user.add_user_to_team',
    INVITE_USER: 'team.user.invite_user',
    RESEND_INVITE: 'team.user.resend_invite',
  },
};
export const USER = {
  DELETE: 'user.delete',
  ENABLE_2FA: 'user.enable_2_fa',
  DISABLE_2FA: 'user.disable_2_fa',
  INVITE_USER: 'user.invite_user',
};
export const ACCOUNT = {
  DELETE: 'account.delete',
  CHANGE_SIGNUP_MODE: 'account.change_signup_mode',
  CONVERT_TO_ORGANIZATION: 'account.convert_to_organization',
  ACCESS_TOKEN: {
    CREATE: 'account.access_token.create',
    DELETE: 'account.access_token.delete',
    UPDATE: 'account.access_token.update',
  },
};
export const IMPROVE = {
  ENVIRONMENT: {
    FILTER_ONLY_ONE_ENVIRONMENT_SELECTED:
      'improve.environment_filter_only_one_environment_selected',
    FILTER_MULTIPLE_ENVIRONMENT_SELECTED:
      'improve.environment_filter_multiple_environments_selected',
    FILTER_CLEAR_ALL: 'improve.environment_filter_clear_all',
  },
  PROJECT_FILTER: {
    FILTER_ONLY_ONE_PROJECT_SELECTED:
      'improve.project_filter_only_one_project_selected',
    FILTER_MULTIPLE_PROJECTS_SELECTED:
      'improve.project_filter_multiple_projects_selected',
    FILTER_CLEAR_ALL_PROJECTS: 'improve.project_filter_clear_all_projects',
    PROJECT_SEARCH: 'improve.project_search',
  },
  NO_PROJECT: 'improve.no_project',
  NO_ACCESS_WARNING_STATE: 'Improve.no_access_warning_state',
  DATE_RANGE: {
    LAST_30_MINUTES: 'improve.date_range_last_30_minutes',
    LAST_ONE_HOUR: 'improve.date_range_last_1_hour',
    LAST_24_HOURS: 'improve.date_range_last_24_hours',
    LAST_7_DAYS: 'improve.date_range_last_7_days',
    LAST_30_DAYS: 'improve.date_range_last_30_days',
    SELECT_FROM_CALENDAR: 'improve.date_range_select_from_calendar',
  },
  SET_DATE_RANGE: {
    TO_NULL: 'improve.set_date_range_to_null',
    FROM_FIRST: 'improve.set_date_range_from_first',
    TO_FIRST: 'improve.set_date_range_to_first',
  },
  COPY_LINK_BUTTON_MENU: 'improve.copy_link_button_in_menu',
  DOWNLOAD_CHART_PNG: 'improve.download_chart_png',
  DOWNLOAD_CHART_DATA: 'improve.download_chart_data',
  APPLY_SET_DATE_RANGE: 'improve.apply_set_date_range',
  SELECT_TIMEZONE: 'improve.select_timezone',
};
export const RQL = {
  DOWNLOAD_REPORT_DATA: 'RQL.download_report_data',
  CREATE_RQL_JOB: 'RQL.create_rql_job',
  CANCEL_RQL_JOB: 'RQL.cancel_rql_job',
};

export const STACK_TRACE = {
  CODE_CONTEXT_ERROR: 'item.codecontext.error',
  CLICK_GIT_BLAME_COMMIT: 'item.gitblame.click-commit',
  CLICK_GIT_BLAME_PR: 'item.gitblame.click-pullrequest',
  VIEW_GIT_BLAME: 'item.gitblame.rendered',
  VIEW_GIT_BLAME_TOOLTIP: 'item.gitblame.view',
};

export const ITEM_DETAIL = {
  ACTION: {
    V7_OPEN_HISTORY: 'v_7.item_detail.open_history',
    V7_COPY_LINK: 'v_7.item_detail.copy_link',
    V7_CHANGE_CHART_TAB: 'v_7.item_detail.context.change_chart_tab',
    V7_OPEN_OCCURRENCES: 'v_7.item_detail.context.open_occurrences',
    V7_OPEN_AFFECTED_IPS: 'v_7.item_detail.context.open_affected_ips',
    V7_OPEN_AFFECTED_PEOPLE: 'v_7.item_detail.context.open_affected_people',
    V7_CHANGED_VIEW: 'v_7.item_detail.change_view',
    V7_CHANGED_TAB: 'v_7.item_detail.change_tab',
    V7_PARAMS_ORDER_BY_TYPICALITY: 'v_7.item_detail.order_by_typicality',
    V7_PARAMS_ORDER_BY_KEY: 'v_7.item_detail.order_by_key',
    V7_DELETE_AN_OCCURRENCE: 'v_7.item_detail.delete_an_occurrence',
    V7_RESOLVE_ITEM: 'v_7.item_detail.resolve_item',
    V7_REOPEN_ITEM: 'v_7.item_detail.reopen_item',
    V7_MUTE_ITEM: 'v_7.item_detail.mute_item',
    V7_OPEN_JSON: 'v_7.item_detail.details.open_json',
    V7_UPDATE_OWNER: 'v_7.item_detail.update_owner',
    V1_SNOOZE_ITEM: 'v_1.snooze_item_detail.snooze_item',
    V1_UNSNOOZE_ITEM: 'v_1.snooze_item_detail.unsnooze_item',
    V1_UNMUTE_ITEM_FROM_SNOOZE: 'v_1.snooze_item_detail.unmute_from_snooze',
  },
};

export const ITEM_LIST = {
  ACTION: {
    SORTED_COLUMN: 'v_7.item_list.sorted_column',
    HIDE_COLUMN: 'v_7.item_list.hide_column',
    DOWNLOAD_CSV: 'v_7.item_list.download_csv',
    CHANGED_AUTO_REFRESH: 'v_7.item_list.changed_auto_refresh',
    USED_SEARCH_BAR: 'v_7.item_list.used_search_bar',
    UPDATED_BULK: 'v_7.item_list.updated_bulk',
    UPDATED_INLINE: 'v_7.item_list.updated_inline',
    SELECTED_FILTER: 'v_7.item_list.selected_filter',
    CLICKED_LINK: 'v_7.item_list.clicked_link',
    APPLIED_SAVED_VIEW: 'v_7.item_list.applied_saved_view',
    CREATED_SAVED_VIEW: 'v_7.item_list.created_saved_view',
    UPDATED_SAVED_VIEW: 'v_7.item_list.updated_saved_view',
    DELETED_SAVED_VIEW: 'v_7.item_list.deleted_saved_view',
  },
};

export const ANALYZE = {
  ACTION: {
    RQL_TAB_VIEWED: 'Analyze.RQL.tab.viewed',
    RQL_EDITOR_TAB_VIEWED: 'Analyze.RQLEditor.tab.viewed',
    RQL_ASSISTANT_TAB_VIEWED: 'Analyze.RQLAssistant.tab.viewed',
    RQL_TEMPLATE_TAB_VIEWED: 'Analyze.RQLTemplate.tab.viewed',
    METRICS_API_TAB_VIEWED: 'Analyze.MetricsAPI.viewed',
    SAMPLE_QUERY_CLICKED: 'Analyze.RQL.sample_query.clicked',
    SAMPLE_QUERY_TAB_CLICKED: 'Analyze.RQL.sample_tab.clicked',
    RQL_DOCS_CLICKED: 'Analyze.RQL.documentation.clicked',
    RQL_UPGRADE_CLICKED: 'Analyze.RQL.upgrade.clicked',
    METRICS_API_UPGRADE_CLICKED: 'Analyze.MetricsAPI.upgrade.clicked',
    WARNING_BANNER_UPGRADE_CLICKED: 'Analyze.warnBanner.upgrade.clicked',
    INFO_BANNER_UPGRADE_CLICKED: 'Analyze.infoBanner.upgrade.clicked',

    RQL_RUN_CLICKED: 'Analyze.RQL.run.clicked',
    RQL_RUN_ERROR: 'Analyze.RQL.run.error',

    AI_RQL_CREATED: 'Analyze.RQL.AI.created',
    AI_RQL_CLICK: 'Analyze.RQL.AI.clicked',
    AI_RQL_ERROR: 'Analyze.RQL.AI.error',
    AI_LEGAL_APPROVED: 'Analyze.RQL.AI.legal.approved',
    AI_LEGAL_REMOVED: 'Analyze.RQL.AI.legal.removed',
    AI_LEGAL_CLICK: 'Analyze.RQL.AI.legal.clicked',
  },
};

export const USAGE = {
  CONTACT_US_CLICKED: 'usage.contact_us_clicked',
  PROJECT_SLIDEOVER: {
    OPENED: 'usage.project_slidover.opened',
    CLOSED: 'usage.project_slidover.closed',
  },
  TAB_CHANGED: 'usage.tab_changed',
  TIME_FRAME_DROPDOWN_CHANGED: 'usage.time_frame_dropdown_changed',
  INFO_TOOLTIP_DISPLAYED: 'usage.info_tooltip_displayed',
  PROJECT_FILTER_CHANGED: 'usage.project_filter_changed',
  SET_RATE_LIMIT_CLICKED: 'usage.set_rate_limit_clicked',
  CSV_DOWNLOAD: 'usage.csv_download',
  CHANGE_PLAN_CLICKED: 'usage.change_plan',
  BILLING_INFO_CLICKED: 'usage.billing_info',
  UPGRADE_PLAN_CLICKED: 'usage.upgrade_plan',
  SORTING_CHANGED: 'usage.sorting_changed',
};

export const USAGE_NOTIFICATIONS = {
  NOTIFICATIONS_SLIDEOVER: {
    OPENED: 'usage.notifications.slideover.open',
    CLOSED: 'usage.notifications.slideover.close',
  },
  SLACK_ENABLED: 'usage.notifications.slack.enabled',
  SLACK_CHANNEL_ADDED: 'usage.notifications.slack.channel_added',
  EMAIL_ENABLED: 'usage.notifications.email.enabled',
  EMAIL_ADDED: 'usage.notifications.email.email_added',
};

export const PROJECT_TOKENS_PAGE = {
  CREATE: {
    OPENED: 'project_tokens.create.open',
    CLOSED: 'project_tokens.create.close',
    SUBMIT: 'project_tokens.create.submit',
  },
  EDIT: {
    OPENED: 'project_tokens.edit.open',
    CLOSED: 'project_tokens.edit.close',
    SUBMIT: 'project_tokens.edit.submit',
  },
  REGENERATE: {
    ALL: {
      OPENED: 'project_tokens.regenerate.all.open',
      CLOSE: 'project_tokens.regenerate.all.close',
    },
    All_EXPIRING_SOON: {
      OPENED: 'project_tokens.regenerate.all_expiring_soon.open',
      CLOSED: 'project_tokens.regenerate.all_expiring_soon.close',
    },
    All_EXPIRED: {
      OPENED: 'project_tokens.regenerate.all_expired.open',
      CLOSED: 'project_tokens.regenerate.all_expired.close',
    },
    MULTIPLE: {
      OPENED: 'project_tokens.regenerate.multiple.open',
      CLOSED: 'project_tokens.regenerate.multiple.close',
      SUBMIT: 'project_tokens.regenerate.multiple.submit',
    },
    SINGLE: {
      OPENED: 'project_tokens.regenerate.single.open',
      CLOSED: 'project_tokens.regenerate.single.close',
      SUBMIT: 'project_tokens.regenerate.single.submit',
    },
  },
  DISABLE: {
    MULTIPLE: {
      OPENED: 'project_tokens.disable.multiple.open',
      CLOSED: 'project_tokens.disable.multiple.close',
      SUBMIT: 'project_tokens.disable.multiple.submit',
    },
    SINGLE: {
      OPENED: 'project_tokens.disable.single.open',
      CLOSED: 'project_tokens.disable.single.close',
      SUBMIT: 'project_tokens.disable.single.submit',
    },
  },
  ENABLE: {
    MULTIPLE: {
      OPENED: 'project_tokens.enable.multiple.open',
      CLOSED: 'project_tokens.enable.multiple.close',
      SUBMIT: 'project_tokens.enable.multiple.submit',
    },
    SINGLE: {
      OPENED: 'project_tokens.enable.single.open',
      CLOSED: 'project_tokens.enable.single.close',
      SUBMIT: 'project_tokens.enable.single.submit',
    },
  },
  EXPIRE: {
    MULTIPLE: {
      OPENED: 'project_tokens.expire.multiple.open',
      CLOSED: 'project_tokens.expire.multiple.close',
      SUBMIT: 'project_tokens.expire.multiple.submit',
    },
    SINGLE: {
      OPENED: 'project_tokens.expire.single.open',
      CLOSED: 'project_tokens.expire.single.close',
      SUBMIT: 'project_tokens.expire.single.submit',
    },
  },
  DELETE: {
    MULTIPLE: {
      OPENED: 'project_tokens.delete.multiple.open',
      CLOSED: 'project_tokens.delete.multiple.close',
      SUBMIT: 'project_tokens.delete.multiple.submit',
    },
    SINGLE: {
      OPENED: 'project_tokens.delete.single.open',
      CLOSED: 'project_tokens.delete.single.close',
      SUBMIT: 'project_tokens.delete.single.submit',
    },
  },
  TABLE: {
    SORT: 'project_tokens.table.sort',
  },
  DOCS: {
    CLICKED: 'project_tokens.docs.clicked',
  },
  COPY_VALUE: 'project_tokens.copy_value',
};

export const ACCOUNT_TOKENS_PAGE = {
  CREATE: {
    OPENED: 'account_tokens.create.open',
    CLOSED: 'account_tokens.create.close',
    SUBMIT: 'account_tokens.create.submit',
  },
  EDIT: {
    OPENED: 'account_tokens.edit.open',
    CLOSED: 'account_tokens.edit.close',
    SUBMIT: 'account_tokens.edit.submit',
  },
  REGENERATE: {
    ALL: {
      OPENED: 'account_tokens.regenerate.all.open',
      CLOSE: 'account_tokens.regenerate.all.close',
    },
    All_EXPIRING_SOON: {
      OPENED: 'account_tokens.regenerate.all_expiring_soon.open',
      CLOSED: 'account_tokens.regenerate.all_expiring_soon.close',
    },
    All_EXPIRED: {
      OPENED: 'account_tokens.regenerate.all_expired.open',
      CLOSED: 'account_tokens.regenerate.all_expired.close',
    },
    MULTIPLE: {
      OPENED: 'account_tokens.regenerate.multiple.open',
      CLOSED: 'account_tokens.regenerate.multiple.close',
      SUBMIT: 'account_tokens.regenerate.multiple.submit',
    },
    SINGLE: {
      OPENED: 'account_tokens.regenerate.single.open',
      CLOSED: 'account_tokens.regenerate.single.close',
      SUBMIT: 'account_tokens.regenerate.single.submit',
    },
  },
  DISABLE: {
    MULTIPLE: {
      OPENED: 'account_tokens.disable.multiple.open',
      CLOSED: 'account_tokens.disable.multiple.close',
      SUBMIT: 'account_tokens.disable.multiple.submit',
    },
    SINGLE: {
      OPENED: 'account_tokens.disable.single.open',
      CLOSED: 'account_tokens.disable.single.close',
      SUBMIT: 'account_tokens.disable.single.submit',
    },
  },
  ENABLE: {
    MULTIPLE: {
      OPENED: 'account_tokens.enable.multiple.open',
      CLOSED: 'account_tokens.enable.multiple.close',
      SUBMIT: 'account_tokens.enable.multiple.submit',
    },
    SINGLE: {
      OPENED: 'account_tokens.enable.single.open',
      CLOSED: 'account_tokens.enable.single.close',
      SUBMIT: 'account_tokens.enable.single.submit',
    },
  },
  EXPIRE: {
    MULTIPLE: {
      OPENED: 'account_tokens.expire.multiple.open',
      CLOSED: 'account_tokens.expire.multiple.close',
      SUBMIT: 'account_tokens.expire.multiple.submit',
    },
    SINGLE: {
      OPENED: 'account_tokens.expire.single.open',
      CLOSED: 'account_tokens.expire.single.close',
      SUBMIT: 'account_tokens.expire.single.submit',
    },
  },
  DELETE: {
    MULTIPLE: {
      OPENED: 'account_tokens.delete.multiple.open',
      CLOSED: 'account_tokens.delete.multiple.close',
      SUBMIT: 'account_tokens.delete.multiple.submit',
    },
    SINGLE: {
      OPENED: 'account_tokens.delete.single.open',
      CLOSED: 'account_tokens.delete.single.close',
      SUBMIT: 'account_tokens.delete.single.submit',
    },
  },
  TABLE: {
    SORT: 'account_tokens.table.sort',
  },
  DOCS: {
    CLICKED: 'account_tokens.docs.clicked',
  },
  COPY_VALUE: 'account_tokens.copy_value',
};

export const BANNERS = {
  ACTIONS: {
    PROJECT_TOKEN_NEEDS_REGENERATING_CLICKED:
      'banner.project_token_needs_regenerating.clicked',
  },
};

export const PAGINATION = {
  PREV_NEXT_PAGINATION: {
    PAGE_SIZE_CHANGED: 'prev_next_pagination.page_size_changed',
    PAGE_CHANGED_NEXT: 'prev_next_pagination.page_changed_next',
    PAGE_CHANGED_PREV: 'prev_next_pagination.page_changed_prev',
    PAGE_CHANGED_FIRST: 'prev_next_pagination.page_changed_first',
  },
};

export const SOURCEMAP_ERRORS_SHEET = {
  OPENED: 'v_7.item_detail.source_map_error.clicked',
  CLEARED: 'v_7.item_detail.source_map_error.cleared',
  CLOSED: 'v_7.item_detail.source_map_error.sidebar_closed',
  SHEET: {
    NEXT: 'v_7.item_detail.source_map_error.sidebar_next',
    PREV: 'v_7.item_detail.source_map_error.sidebar_prev',
    DOC: 'v_7.item_detail.source_map_error.slideover.documentation',
    VERSION: 'v_7.item_detail.source_map_error.slideover.code_version',
  },
  UPLOAD: {
    OPENED: 'v_7.item_detail.source_map_error.upload.opened',
    CLOSED: 'v_7.item_detail.source_map_error.upload.closed',
    DOC: 'v_7.item_detail.source_map_error.upload.documentation',
    SUCCESS: 'v_7.item_detail.source_map_error.upload.uploaded',
    FAILURE: 'v_7.item_detail.source_map_error.upload.upload_error',
  },
};

export const YOUR_PLAN = {
  OPEN_COMPARE_PAGE: 'your_plan.open_compare_page',
  OPEN_VIEW_FULL_USAGE_GRAPHS: 'your_plan.open_view_full_usage_graphs',
  CHANGE_ON_DEMAND_OCCURRENCES: {
    SET_ON_DEMAND_OFF: 'your_plan.change.set_on_demand_off',
    SET_ON_DEMAND_LIMITED: 'your_plan.change.set_on_demand_limited',
    SET_ON_DEMAND_UNLIMITED: 'your_plan.change.set_on_demand_unlimited',
  },
  CANCEL_PLAN: 'your_page.cancel_plan',
};

export const COMPARE_PLANS_PAGE = {
  UPSELL: {
    REFERAL_PAGE: 'compare_plans_page.upsell.referal_page',
    FEATURE: 'compare_plans_page.upsell.feature',
    SELECTED_PLAN: 'compare_plans_page.upsell.selected_plan',
  },
  CHOOSE_OCCURRENCE_LIMIT: 'compare_plans_page.choose_occurrence_limit',
  CHANGE_BILLING: 'compare_plans_page.change_billing',
  CHOOSE_PLAN: 'compare_plans_page.choose_plan',
  CTA: {
    CLICK_UPGRADE_BUTTON: 'compare_plans_page.cta.click_upgrade_button',
    CLICK_DOWNGRADE_BUTTON: 'compare_plans_page.cta.click_downgrade_button',
    CLICK_CANCEL_BUTTON: 'compare_plans_page.cta.click_cancel_button',
    CLICK_CONTINUE_TO_PAYMENT_BUTTON:
      'compare_plans_page.cta.click_continue_to_payment_button',
    CLICK_CHANGE_BILLING_BUTTON:
      'compare_plans_page.cta.click_change_billing_button',
    CLICK_REACTIVATE_BUTTON: 'compare_plans_page.cta.click_reactivate_button',
  },
  MODAL: {
    CONFIRM: {
      UPGRADE: 'compare_plans_page.modal.confirm.upgrade',
      DOWNGRADE: 'compare_plans_page.modal.confirm.downgrade',
      CANCEL: 'compare_plans_page.modal.confirm.cancel',
      CHECKOUT: 'compare_plans_page.modal.confirm.checkout',
      CHANGE_BILLING: 'compare_plans_page.modal.confirm.change_billing',
      REACTIVATE: 'compare_plans_page.modal.confirm.reactivate',
    },
    CANCEL: {
      UPGRADE: 'compare_plans_page.modal.cancel.upgrade',
      DOWNGRADE: 'compare_plans_page.modal.cancel.downgrade',
      CANCEL: 'compare_plans_page.modal.cancel.cancel',
      CHECKOUT: 'compare_plans_page.modal.cancel.checkout',
      CHANGE_BILLING: 'compare_plans_page.modal.cancel.change_billing',
      REACTIVATE: 'compare_plans_page.modal.cancel.reactivate',
    },
  },
};

export const WELCOME_PAGE = {
  WELCOME_CARD: {
    CLICKED_STEP_PREFIX: 'dashboard_checklist_clicked',
    CLICKED_CONTACT_SUPPORT: 'dashboard_checklist_contact_support',
    RESET_HINTS: 'dashboard_checklist_resetHint',
    DISMISS_AND_REMOVE_HINTS: 'dashboard_checklist_removeHints',
  },
  CARD_ACTIONS: {
    DISMISS_CARD_PREFIX: 'dashboard_card_hide',
    SHOW_CARD_PREFIX: 'dashboard_cards_show',
    COPY_SHARE_LINK: 'dashboard_card_share',
  },
  TIP_ACTIONS: {
    DISMISS_PREFIX: 'dashboard_tips_hide',
    CLICKED_LINK_PREFIX: 'dashboard_tips_link',
  },
  OCCURRENCES_CARD: {
    OPEN_PROJECT_FILTER: 'dashboard_occurrences_openFilter',
    CLICKED_COMPARE: 'dashboard_occurrences_compare',
    CLICKED_24_HOURS: 'dashboard_occurrences_24Hours',
    CLICKED_7_DAYS: 'dashboard_occurrences_7Days',
    CLICKED_PROJECT_NAME: 'dashboard_occurrences_projectDashboard',
    CLICKED_PROJECT_SETTINGS: 'dashboard_occurrences_projectSettings',
    CLICKED_DEPLOYS: 'dashboard_occurrences_deploys',
    CLICKED_VIEW_ALL_PROJECT_ITEMS: 'dashboard_occurrences_projectItems',
  },
  PROJECTS_CARD: {
    CLICKED_PROJECT_NAME: 'dashboard_projects_viewProject',
    CLICKED_CREATE_PROJECT: 'dashboard_projects_createProject',
    CLICKED_MANAGE_PROJECTS: 'dashboard_projects_manageProjects',
    CLICKED_CREATE_INTEGRATION: 'dashboard_projects_createIntegration',
    CLICKED_PROJECT_SETTINGS: 'dashboard_projects_projectSettings',
    CLICKED_PROJECT_DUPLICATE: 'dashboard_projects_projectDuplication',
    CLICKED_PROJECT_DISABLE: 'dashboard_projects_projectDisable',
    CLICKED_PROJECT_DELETE: 'dashboard_projects_projectDelete',
    CLICKED_MORE_PROJECTS: 'dashboard_projects_moreProjects',
  },
  INTEGRATIONS_CARD: {
    CLICKED_ADD_INTEGRATION: 'dashboard_integrations_addIntegration',
    EXPANDED_INTEGRATION: 'dashboard_integrations_expandIntegration',
    CLICKED_VIEW_INTEGRATION: 'dashboard_integrations_viewIntegration',
    CLICKED_MORE_PROJECTS: 'dashboard_integrations_moreProjects',
  },
  TEAMS_CARD: {
    CLICKED_MANAGE_TEAMS: 'dashboard_teams_manageTeams',
    CLICKED_MORE_TEAMS: 'dashboard_teams_moreTeams',
  },
  USERS_CARD: {
    CLICKED_MANAGE_USERS: 'dashboard_users_manageUsers',
    CLICKED_MORE_USERS: 'dashboard_users_moreUsers',
  },
};

export const UPSELL = {
  MODAL_OPEN: 'upsell_modal-open',
  UPGRADE: 'upsell_modal-upgrade',
  MORE_INFO: 'upsell_modal-info',
};
