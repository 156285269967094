import EmailPasswordWebJs from 'supertokens-web-js/recipe/emailpassword';
import Multitenancy from 'supertokens-web-js/recipe/multitenancy';
import SessionWebJs from 'supertokens-web-js/recipe/session';
import ThirdPartyWebJs from 'supertokens-web-js/recipe/thirdparty';
import { SuperTokensConfig } from 'supertokens-web-js/types';

import { appInfo } from './appInfo';

export const frontendConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      Multitenancy.init({
        override: {
          functions: (oI) => ({
            ...oI,
            getTenantId: async (input) => {
              const { userContext } = input;
              if (userContext?.tenantId) {
                return userContext.tenantId;
              }
              return oI.getTenantId(input);
            },
          }),
        },
      }),
      ThirdPartyWebJs.init(),
      EmailPasswordWebJs.init(),
      SessionWebJs.init(),
    ],
  };
};
