export const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NEXT_PUBLIC_ROLLBAR_ENVIRONMENT,
    code_version: process.env.NEXT_PUBLIC_SHA,
    client: {
      javascript: { code_version: process.env.NEXT_PUBLIC_SHA },
    },
    server: {
      root:
        process.env.NODE_ENV === 'development'
          ? 'webpack-internal:///./src/'
          : 'webpack://_N_E/./src/',
      branch: process.env.NEXT_PUBLIC_BRANCH,
    },
  },
};

export const serverConfig = {
  ...baseConfig,
  accessToken: process.env.ROLLBAR_SERVER_TOKEN,
};
