import { DashboardCardTemplate } from '@/generated/graphql';

import { Flags } from './flags';
import useAccountUrl from './useAccountUrl';
import useFlag from './useFlag';
import useMoxWebAccountSettingsUrl from './useMoxWebAccountSettingsUrl';

const { NEXT_PUBLIC_ROLLBAR_BASE_URL } = process.env;

type projectUrlFunc = (account: string, project: string) => string;

export const projectSettingsUrl: projectUrlFunc = (account, project) =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/${account}/${project}/settings`;

export const projectMembersUrl: projectUrlFunc = (account, project) =>
  `${projectSettingsUrl(account, project)}/collaborators`;

export const projectIntegrationUrl = (
  account: string,
  project: string,
  integration: string
) =>
  `${projectSettingsUrl(
    account,
    project
  )}/notifications/${integration.toLocaleLowerCase()}/`;

export const useTeamSettingsLink = () => {
  const useOlympusTeamSettings = useFlag(Flags.OLYMPUS_TEAMS_SETTINGS);
  const moxTeamSettingsLink = useMoxWebAccountSettingsUrl('teams');
  const olympusTeamSettingsLink = useAccountUrl('/settings/teams');
  return useOlympusTeamSettings ? olympusTeamSettingsLink : moxTeamSettingsLink;
};

export const useComparePlansUrl = () => {
  return useAccountUrl('/settings/subscriptions/change');
};

export const useSingleDashboardCardUrl = (template: DashboardCardTemplate) => {
  return useAccountUrl(`/dashboard/card/${template.toLowerCase()}`);
};

export const googleLoginCallbackUrl = () =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/callback/google_oauth_login`;

export const securitySettingsUrl = (account: string) =>
  `/a/${account}/settings/security`;

// saml settings
export const addIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp/add`;

export const viewIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp`;

export const editIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp/edit`;
